import React from 'react';
import { Table } from 'antd';
import { PropTypes } from 'prop-types';

const TableTemplate = ({
  columns,
  data,
  expandable,
  typeList,
  setCurrentHoverRowList,
  components,
  className,
  rowClassName,
  pagination,
  origin,
  // eslint-disable-next-line react/prop-types
  onChange,
}) => {
  const getScrollConfig = () => {
    const fullHeightOffset = origin === 'drawer' ? 145 : 340;
    if (typeList === 'fullHeight') {
      return { y: window.innerHeight - fullHeightOffset };
    }
    return { y: 220 };
  };

  const onRow = (record) => ({
    onMouseEnter: () => setCurrentHoverRowList(record),
    onMouseLeave: () => setCurrentHoverRowList(null),
  });

  const paginationConfig = JSON.stringify(pagination) !== '{}' ? pagination : false;

  return (
    <Table
      rowClassName={rowClassName}
      className={className}
      onRow={onRow}
      columns={columns}
      dataSource={data}
      size="small"
      bordered
      scroll={getScrollConfig()}
      expandable={expandable}
      pagination={paginationConfig}
      components={components}
      onChange={onChange}
    />
  );
};

TableTemplate.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  expandable: PropTypes.shape({}),
  typeList: PropTypes.string,
  setCurrentHoverRowList: PropTypes.func,
  components: PropTypes.shape(),
  className: PropTypes.string,
  rowClassName: PropTypes.func,
  origin: PropTypes.string,
  pagination: PropTypes.shape({}),
};

TableTemplate.defaultProps = {
  pagination: {},
  expandable: {},
  typeList: '',
  setCurrentHoverRowList: () => {},
  components: {},
  className: '',
  rowClassName: () => {},
  origin: '',
};

export default TableTemplate;
