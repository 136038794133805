export const blackColor = '#000000';
export const blueColor = '#0000FF';
export const blueAzurColor = '#0878f7';
export const blueAzur2Color = '#3993f9';
export const blueLightColor = '#00B1E4';
export const blueLight2Color = '#33d3ff';
export const bluePrimaryColor = '#0976b3';
export const greenLightColor = '#00A651';
export const greenPrimaryColor = '#007A33';
export const paleGreenColor = '#77BFA3';
export const whiteColor = '#fff';
export const redColor = '#ff0000';
export const orangeColor = '#EF9C00';
export const greyColor = '#808080';
export const lightGrey = '#dedede';
export const violetColor = '#7F00FF';
