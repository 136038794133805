import React from 'react';
import PropTypes from 'prop-types';
import { Timeline } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  AudioOutlined,
} from '@ant-design/icons';
import CustomDrawer from '../../../drawer/CustomDrawer';
import { formatDate, getTagIcon, getTagText } from '../../../../../utils/utils';
import { blackColor, blueAzurColor, greenLightColor } from '../../../../../assets/styles/colors';
import Statistics from './Statistics';

const TimelineTagDrawer = ({
  isOpenTimelineTagDrawer,
  setIsOpenTimelineTagDrawer,
  tags,
  currentRecord,
}) => {
  const { t } = useTranslation();
  const currentRecordDate = formatDate(currentRecord?.timestamp, t('format.date'));

  const timelineItems = [
    {
      color: blueAzurColor,
      label: currentRecordDate,
      children: t('records.timelineDrawer.record'),
      dot: <AudioOutlined />,
    },
    ...(tags?.map((item, index) => {
      const { tag, timestamp } = item;
      const isLastItem = index === tags.length - 1;
      const tagDate = formatDate(timestamp, t('format.date'));
      return {
        color: isLastItem ? greenLightColor : blackColor,
        label: tagDate,
        children: getTagText(tag, t),
        dot: getTagIcon(tag),
      };
    }) || []),
  ];

  return (
    <CustomDrawer
      className="timeline-tag-drawer"
      placement="right"
      title={t('records.timelineDrawer.title')}
      width={650}
      isOpenDrawer={isOpenTimelineTagDrawer}
      setIsOpenDrawer={setIsOpenTimelineTagDrawer}
    >
      <Timeline className="timeline-tag" mode="left" items={timelineItems} />
      <Statistics tags={tags} currentRecord={currentRecord} />
    </CustomDrawer>
  );
};

export default TimelineTagDrawer;

TimelineTagDrawer.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      tag: PropTypes.number.isRequired,
      timestamp: PropTypes.number.isRequired,
    }),
  ).isRequired,
  isOpenTimelineTagDrawer: PropTypes.bool.isRequired,
  setIsOpenTimelineTagDrawer: PropTypes.func.isRequired,
  currentRecord: PropTypes.shape({
    timestamp: PropTypes.number.isRequired,
  }).isRequired,
};
