import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

const CustomModal = ({
  isOpenModal, setIsOpenModal, width, children, title, ref,
}) => {
  const handleCloseModal = () => {
    setIsOpenModal(false);
    const audioElt = document.getElementById('player');
    if (audioElt) {
      audioElt.pause();
      audioElt.currentTime = 0;
    }
    if (ref) {
      ref.pause();
    }
  };

  return (
    <Modal
      ref={ref}
      width={width}
      title={title}
      open={isOpenModal}
      onCancel={handleCloseModal}
      footer={null}
    >
      { children }
    </Modal>
  );
};

CustomModal.propTypes = {
  title: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  isOpenModal: PropTypes.bool,
  setIsOpenModal: PropTypes.func.isRequired,
  ref: PropTypes.func.isRequired,
};

CustomModal.defaultProps = {
  title: '',
  width: 800,
  isOpenModal: false,
};

export default CustomModal;
