import React, { useContext } from 'react';
import { monitoringComponents } from '../../../../utils/utils';
import IndicatorCard from './IndicatorCard';
import { AuthContext } from '../../../../utils/context/AuthContext';

const sortedMonitoringComponents = [...monitoringComponents].sort((a, b) => a.order - b.order);

const IndicatorCards = () => {
  const { role } = useContext(AuthContext);

  const filteredComponents = role === 1
    ? sortedMonitoringComponents
    : sortedMonitoringComponents.filter((item) => item.role !== 1);

  return (
    <article className="indicator-cards">
      {filteredComponents?.map((item) => {
        if (item.type === 'indicator') {
          return (
            <IndicatorCard
              key={item.id}
              fill={item.fill}
              title={item.title}
              source={item.source}
            />
          );
        }
        return null;
      })}
    </article>
  );
};

export default IndicatorCards;
