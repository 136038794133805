import PropTypes from 'prop-types';
import PrintButton from '../PrintButton';

const MobileInfosTitle = ({ title, showPrint }) => (
  <section className="mobile-infos-title">
    {title}
    {showPrint && <PrintButton />}
  </section>
);

MobileInfosTitle.propTypes = {
  title: PropTypes.string.isRequired,
  showPrint: PropTypes.bool.isRequired,
};

export default MobileInfosTitle;
