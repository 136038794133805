import React from 'react';
import PropTypes from 'prop-types';
import RecordCharts from '../charts/RecordCharts';
import SynthesisCards from '../synthesisCards';

const RecordMobileInfosModal = ({
  typeChart,
  currentRecord = {},
}) => (
  <>
    <RecordCharts
      currentRecord={currentRecord}
      typeChart={typeChart}
      isModal
    />
    <SynthesisCards currentRecord={currentRecord} />
  </>
);

RecordMobileInfosModal.propTypes = {
  currentRecord: PropTypes.shape({
    densityPower: PropTypes.arrayOf(PropTypes.number),
    timeStamp: PropTypes.number,
    telephoneNumber: PropTypes.string,
  }),
  typeChart: PropTypes.string,
};

RecordMobileInfosModal.defaultProps = {
  currentRecord: null,
  typeChart: null,
};

export default RecordMobileInfosModal;
