import action from '../utils/actionConf';
import createRequestTypes from '../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST = 'POST';

export const ANALYSIS = createRequestTypes('ANALYSIS', [FETCH]);
export const ANALYSISTAG = createRequestTypes('ANALYSISTAG', [POST]);
export const ANALYSISDETAILS = createRequestTypes('ANALYSISDETAILS', [FETCH]);
export const WAVEFILE = createRequestTypes('WAVEFILE', [FETCH]);

export const getAnalysisAction = {
  request: (payload) => action(ANALYSIS.FETCH.REQUEST, payload),
  success: (data) => action(ANALYSIS.FETCH.SUCCESS, data),
  error: (message) => action(ANALYSIS.FETCH.ERROR, message),
  failure: (error) => action(ANALYSIS.FETCH.FAILURE, error),
};

export const postAnalysisTagAction = {
  request: (payload) => action(ANALYSISTAG.POST.REQUEST, payload),
  success: (data) => action(ANALYSISTAG.POST.SUCCESS, data),
  failure: (error) => action(ANALYSISTAG.POST.FAILURE, error),
};

export const getAnalysisDetailsAction = {
  request: (payload) => action(ANALYSISDETAILS.FETCH.REQUEST, payload),
  success: (data) => action(ANALYSISDETAILS.FETCH.SUCCESS, data),
  failure: (error) => action(ANALYSISDETAILS.FETCH.FAILURE, error),
};

export const getWaveFileAction = {
  request: (payload) => action(WAVEFILE.FETCH.REQUEST, payload),
  success: (data) => action(WAVEFILE.FETCH.SUCCESS, data),
  failure: (error) => action(WAVEFILE.FETCH.FAILURE, error),
};
