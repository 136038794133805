import api from '../utils/query';

const baseUrl = 'analysis_sound';

export const getAnalysisService = (token) => api.get(baseUrl, token);

export const getAnalysisByUserService = (token) => {
  const apiUrl = `${baseUrl}/user`;
  return api.get(apiUrl, token);
};

export const postAnalysisTagService = (payload) => {
  const { id, tag } = payload;
  const apiUrl = `${baseUrl}/${id}/tag/${tag}`;
  return api.create(apiUrl, payload);
};

export const getWavefileService = (payload) => {
  const { id, token } = payload;
  const apiUrl = `${baseUrl}/${id}/wavefile`;
  return api.get(apiUrl, token);
};
