import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const AudioPlayer = () => {
  const [audioSrc, setAudioSrc] = useState(null);
  const { wavefile } = useSelector((state) => state.analysis);

  const convertBase64ToAudio = () => `data:audio/mpeg;base64,${wavefile}`;

  useEffect(() => {
    if (wavefile) {
      const audioUrl = convertBase64ToAudio();
      setAudioSrc(audioUrl);
    }
  }, [wavefile]);
  return (
    <audio id="player" controls src={audioSrc} autoPlay />
  );
};

export default AudioPlayer;
