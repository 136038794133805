import styled from 'styled-components';
import { Card, Layout, Menu } from 'antd';
import {
  blueLightColor,
  bluePrimaryColor, whiteColor,
} from '../../assets/styles/colors';

const { Sider, Header } = Layout;

export const LayoutComponent = styled(Layout)`
  .private-content {
    height: 800px;
    transition: .4s;
    .main-container {
      transition: .2s;

        &.collapsed {
          padding-left: 90px;
        }
      }
    }
`;
export const PageContainer = styled.section`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    height: ${({ screenHeight }) => screenHeight - 183}px;
    overflow-y: ${({ overflowHidden }) => (overflowHidden ? 'hidden' : 'scroll')};
    padding-bottom: 200px;
`;

export const SiderComponent = styled(Sider)`
  && {
    height: 100vh;
    padding-top: 20px;
    background-color: ${whiteColor};
    border-right: 1px solid #00a4ff1a;
    box-shadow: 4px -7px 20px -1px rgba(104, 92, 92, 0.1);
    z-index: 200;
  }
  .aside-header {
    display: block;
    text-align: center;
  }
  .aside-logo {
    width: 60px;
  }
  .ant-menu-item .ant-menu-item-icon,
  .ant-menu-item .item-link {
    color: ${bluePrimaryColor};
    font-size: 16px;
    font-weight: 700;

    &:hover {
      color: ${bluePrimaryColor};
    }
  }
`;

export const MenuComponent = styled(Menu)`
  margin-top: 60px;
  border: none;
  color: grey;
  border-radius: 0 10px 10px 0px;

  li:not(.ant-menu-item-selected) {
    a {
      color: grey;
      opacity: .85;

      &:hover {
        color: black;
      }
    }
  }
  .anticon {
    display: inline-block;
    font-size: 20px;
    vertical-align: middle;
  }
  &.ant-menu {
    &.ant-menu-inline-collapsed {
      .anticon {
        line-height: 40px;

        &::after {
          display: none;
        }
      }
    }
  }
`;

export const HeaderComponent = styled(Header)`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 100%;
    background-color: ${whiteColor}; 
    border-bottom: 1px solid #afafaf4d; 
    box-shadow: 4px -7px 10px 10px rgba(104, 92, 92, 0.1);

    .title {
      font-size: 26px;
      color: ${bluePrimaryColor};
    }
    .user-block {
      display: flex;
      gap: 12px;

      .anticon svg {
      font-size: 23px;
      color: ${bluePrimaryColor};
      cursor: pointer;
      transition: .3s;

      &:hover {
        color: ${blueLightColor};
      }
    }
  }
}
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const Message = styled.h1`
  font-size: 2rem;
  color: red;
`;

export const StyledCard = styled(Card)`
  min-width: 300px;
  width: ${({ width }) => `${width}px`};

  .ant-card-body {
    display: flex;
    justify-content: space-evenly;
  }
`;

const DashboardList = styled.article`
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => (padding ? `${padding}` : '15px')};
  max-width: 800px;
  min-width: 200px;
  width: ${({ width }) => (width ? `${width}px` : '800px')};
  max-height: ${({ maxHeight }) => maxHeight || '430px'};
  margin: ${({ margin }) => (margin ? `${margin}px auto` : '40px auto')};
  background-color: rgba(255, 255, 255, .1);
  border-radius: 25px;
  backdrop-filter: blur(4px);
  box-shadow: -1px -2px 5px rgba(255, 255, 255, .3), 1px 2px 8px rgba(0, 0, 0, .3);
  overflow: hidden;

  .header-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const CardStyled = styled.article`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width}px;
  padding: 20px;
  max-width: 800px;
  min-width: 200px;
  margin: ${({ margin }) => (margin ? `${margin}px` : '40px auto')};
  background-color: rgba(255, 255, 255, .1);
  border-radius: 25px;
  backdrop-filter: blur(4px);
  box-shadow: -1px -2px 5px rgba(255, 255, 255, .3), 1px 2px 8px rgba(0, 0, 0, .3);

  .header-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
`;

export const StyledWaveSVG = styled.svg`
  position: absolute;
  fill: ${({ fill }) => (fill)};
  bottom: 0;
  left: 0;
`;

export default DashboardList;
