import { Layout } from 'antd';
import React, {
  useState, useCallback,
  useEffect,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import RecordsList from '../shared/recordElements/RecordsList';
import { getAnalysisAction } from '../../business/actions/analysisAction';
import UpdateData from '../shared/UpdateData';
import MobilesDetailsDrawer from '../shared/mobilesDetails/MobilesDetailsDrawer';
import TimelineTagDrawer from '../shared/recordElements/drawer/timelineTagDrawer';
import CustomModal from '../shared/modal';
import MobileInfosTitle from '../shared/recordElements/MobileInfosTitle';
import { formatDate } from '../../utils/utils';

const endDate = dayjs(dayjs()).valueOf();
const startDate = dayjs(dayjs()).subtract(35, 'days').valueOf();

const Sounds = () => {
  const { analysis } = useSelector((state) => state.analysis);
  const [decisionFilterSelected, setDecisionFilterSelected] = useState([0, 1, 10]);
  const [rangeDateFilterSelected, setRangeDateFilterSelected] = useState([startDate, endDate]);
  const [filterByAgencies, setFilterByAgencies] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  const [showPrint, setShowPrint] = useState();
  const [currentMobile, setCurrentMobile] = useState(null);
  const [isOpenDrawer, setIsOpenDrawer] = useState(!!currentMobile);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isOpenTimelineTagDrawer, setIsOpenTimelineTagDrawer] = useState(false);
  const [timelineTags, setTimelineTags] = useState([]);
  const [typeChart, setTypeChart] = useState('densityPower');
  const { t } = useTranslation();
  const audioRef = useRef(null);

  const renderMobilInfoTitle = () => {
    const numberMobile = currentRecord?.telephoneNumber;
    const date = formatDate(currentRecord?.timeStamp, t('format.dayMonthYearTime'));
    const title = `${numberMobile} - ${date}`;
    const mobileInfosTitle = <MobileInfosTitle title={title} showPrint={showPrint} />;
    return mobileInfosTitle;
  };
  useEffect(() => {
    if (currentRecord && isOpenDrawer) {
      setCurrentMobile({
        telephoneNumber: currentRecord.telephoneNumber,
        selectedMonth: currentRecord.timestamp,
        androidId: currentRecord.androidId,
      });
    }
    setModalTitle(renderMobilInfoTitle());
  }, [currentRecord, isOpenDrawer]);

  const handleShowDrawer = useCallback((record) => {
    setCurrentRecord(record);
    setIsOpenDrawer(true);
  }, []);

  return (
    <Layout className="sounds">
      <CustomModal
        title={modalTitle}
        width={modalWidth}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        ref={audioRef.current}
      >
        {!!modalContent && modalContent}
      </CustomModal>
      <MobilesDetailsDrawer
        currentMobile={currentMobile}
        setCurrentMobile={setCurrentMobile}
        setIsOpenDrawer={setIsOpenDrawer}
        isOpenDrawer={isOpenDrawer}
        currentRecord={currentRecord}
        decisionFilterSelected={decisionFilterSelected}
        rangeDateFilterSelected={rangeDateFilterSelected}
        filterByAgencies={filterByAgencies}
        origin="records"
      />
      <UpdateData stateToUpdate={getAnalysisAction} timeToRefresh={18000} />
      <RecordsList
        recordsList={analysis}
        setCurrentRecord={setCurrentRecord}
        handleShowDrawer={handleShowDrawer}
        setIsOpenTimelineTagDrawer={setIsOpenTimelineTagDrawer}
        setDecisionFilterSelected={setDecisionFilterSelected}
        setRangeDateFilterSelected={setRangeDateFilterSelected}
        setFilterByAgencies={setFilterByAgencies}
        setTimelineTags={setTimelineTags}
        rangeDateFilterSelected={rangeDateFilterSelected}
        typeChart={typeChart}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        setModalContent={setModalContent}
        setModalTitle={setModalTitle}
        setModalWidth={setModalWidth}
        currentRecord={currentRecord}
        setTypeChart={setTypeChart}
        setShowPrint={setShowPrint}
        audioRef={audioRef}
      />
      <TimelineTagDrawer
        setIsOpenTimelineTagDrawer={setIsOpenTimelineTagDrawer}
        isOpenTimelineTagDrawer={isOpenTimelineTagDrawer}
        currentRecord={currentRecord}
        tags={timelineTags}
      />
    </Layout>
  );
};

export default Sounds;
