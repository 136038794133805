import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getDifferenceTimestamps, getTagText } from '../../../../../utils/utils';
import { CardStyled } from '../../../SharedStyle';

const Statistics = ({ tags, currentRecord }) => {
  const { t } = useTranslation();
  const steps = tags.length;
  const firstRecordTimestamp = currentRecord?.timestamp;
  const lastTagTimestamp = tags && tags[tags.length - 1]?.timestamp;
  const startTagTimestamp = tags && tags[0]?.timestamp;
  const diffDecisionValidation = getDifferenceTimestamps(firstRecordTimestamp, lastTagTimestamp);
  const statistics = [];

  for (let i = 0; i < steps; i += 1) {
    const lastStep = i === steps - 1;
    if (i === 0) {
      const diffStep = getDifferenceTimestamps(firstRecordTimestamp, startTagTimestamp);
      statistics.push(
        <React.Fragment key="current-record-step">
          <h4>
            {t('records.timelineDrawer.step', { step: 1 })}
            {getTagText(tags[0]?.tag, t)}
          </h4>
          <p>
            {t('records.timelineDrawer.elapsedTime')}
            {diffStep}
          </p>
        </React.Fragment>,
      );
    } else if (i < tags.length) {
      const startTimestamp = tags[i - 1]?.timestamp;
      const endTimestamp = tags[i]?.timestamp;
      const diffStep = getDifferenceTimestamps(startTimestamp, endTimestamp);
      const titleClassName = lastStep ? 'green' : '';

      statistics.push(
        <React.Fragment key={i}>
          <h4 className={titleClassName}>
            {t('records.timelineDrawer.step', { step: i + 1 })}
            {getTagText(tags[i]?.tag, t)}
          </h4>
          <p>
            {t('records.timelineDrawer.elapsedTime')}
            {diffStep}
          </p>
          {lastStep && (
            <p>
              {t('records.timelineDrawer.decisionValidation')}
              {diffDecisionValidation}
            </p>
          )}
        </React.Fragment>,
      );
    }
  }

  return (
    <CardStyled width={250} margin={1}>
      <h3>{t('records.timelineDrawer.statistics')}</h3>
      {statistics}
    </CardStyled>
  );
};

Statistics.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      tag: PropTypes.number.isRequired,
      timestamp: PropTypes.number.isRequired,
    }),
  ).isRequired,
  currentRecord: PropTypes.shape({
    timestamp: PropTypes.number.isRequired,
  }).isRequired,
};

export default Statistics;
