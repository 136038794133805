import {
  Button, Popover, Select, Tag,
} from 'antd';
import {
  useContext, useEffect, useState, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  SafetyCertificateOutlined, CopyOutlined, EditOutlined, CloseOutlined,
  MessageOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import TableTemplate from '../TableTemplate';
import FilterColumnSearch from '../FilterColumnSearch';
import {
  formatDate,
  getQualificationValue,
  getTriplets,
  renderDecisionAlgo,
  setUniqueList,
  openNotificationInfo,
  getTagText,
  getTagColor,
  getTagIcon,
} from '../../../utils/utils';
import { Context } from '../../../utils/context/Intlcontext';
import { AuthContext } from '../../../utils/context/AuthContext';
import { getWaveFileAction, postAnalysisTagAction } from '../../../business/actions/analysisAction';
import AudioPlayer from './AudioPlayer';
import RecordMobileInfosModal from './modal/RecordMobileInfosModal';
import DateFilterDropdown from './DateFilterDropdown';

dayjs.extend(isBetween);

const RecordsList = ({
  recordsList,
  handleShowDrawer,
  typeChart,
  setTypeChart,
  setIsOpenTimelineTagDrawer,
  setTimelineTags,
  setCurrentRecord,
  setRangeDateFilterSelected,
  setDecisionFilterSelected,
  rangeDateFilterSelected,
  setIsOpenModal,
  setModalContent,
  setModalWidth,
  setShowPrint,
  audioRef,
  setFilterByAgencies,
}) => {
  const [editTagRowKey, setEditTagRowKey] = useState(null);
  const { role, token } = useContext(AuthContext);
  const { initialLang } = useContext(Context);
  const [tableData, setTableData] = useState([]);
  const isFilteredNewDates = [];

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const renderData = () => {
    const data = [];
    if (recordsList) {
      const sortedRecordsList = recordsList.sort((a, b) => b.timeStamp - a.timeStamp);
      sortedRecordsList.forEach((sound) => {
        const { decisionPeakSearchGlobal, decisionPeakSearchBlock, decisionCnn } = sound;
        data.push({
          key: sound.blockChainHash,
          timestamp: sound.timeStamp,
          triplets: getTriplets(decisionPeakSearchGlobal, decisionPeakSearchBlock, decisionCnn),
          analysisTags: sound.analysisTags,
          ...sound,
        });
      });
      setTableData(data);
    }
  };

  useEffect(() => {
    renderData();
  }, [recordsList]);

  const renderAgenciesFilter = () => {
    const agenciesFilter = [];
    const agenciesList = setUniqueList(recordsList, 'agencyName');
    agenciesList?.map((agency) => (
      agenciesFilter.push({ text: agency.agencyName ? agency.agencyName : '--', value: agency.agencyName ? agency.agencyName : '--' })
    ));
    return agenciesFilter;
  };

  const handleCopyToClipboard = (blockChainHash) => () => {
    navigator.clipboard.writeText(blockChainHash);
    openNotificationInfo({
      message: t('records.blockchainCopied'),
      placement: 'top',
    });
  };

  const blockChainPopover = (blockChainHash) => (
    <section className="blockchain-popover">
      <Button
        className="copy-blockchain-btn"
        onClick={handleCopyToClipboard(blockChainHash)}
        icon={<CopyOutlined />}
      />
      <div className="align-center">
        <h3>{t('records.blockchainTitle')}</h3>
        <span>{blockChainHash}</span>
      </div>
    </section>
  );

  const handleTagChange = (tag, id) => {
    dispatch(postAnalysisTagAction.request({
      token,
      role,
      id,
      tag,
    }));
    setEditTagRowKey(null);
  };

  const handleClickTag = (tags, record) => {
    if (tags) {
      setTimelineTags(tags);
      setIsOpenTimelineTagDrawer(true);
      setCurrentRecord(record);
    }
  };

  const tagsOptions = [
    { value: 0, label: t('records.tags.untreated') },
    { value: 1, label: t('records.tags.processed') },
    { value: 2, label: t('records.tags.beingProcessed') },
    { value: 3, label: t('records.tags.underRepair') },
    { value: 4, label: t('records.tags.repaired') },
    { value: 5, label: t('records.tags.falsePositive') },
    { value: 6, label: t('records.tags.falseNegative') },
    { value: 7, label: t('records.tags.toVerify') },
    { value: 8, label: t('records.tags.verified') },
  ];

  const renderFilterDropdown = ({
    setSelectedKeys, selectedKeys, confirm, clearFilters,
  }) => (
    <DateFilterDropdown
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
      rangeDateFilterSelected={rangeDateFilterSelected}
    />
  );
  const handleAgenciesFilter = (value, sound) => {
    const res = sound.agencyName ? sound.agencyName?.startsWith(value) : null;
    return (res);
  };

  const handleDecisionFilter = (value, record) => {
    const showAfterFiltered = record.decision === value;
    if (showAfterFiltered) {
      isFilteredNewDates.push(record.timeStamp);
    }
    return record.decision === value;
  };

  const handleRangeDateFilter = (value, record) => {
    if (value && value[0] && value[1]) {
      setRangeDateFilterSelected(value);
      const date = dayjs(record.timeStamp);
      return date.isBetween(value[0], value[1]);
    }
    return null;
  };
  const handleShowChartModal = useCallback((record, type) => {
    setCurrentRecord(record);
    setTypeChart(type);
    setIsOpenModal(true);
    setModalContent(
      <RecordMobileInfosModal
        typeChart={typeChart}
        currentRecord={record}
      />,
    );
    setModalWidth(800);
    setShowPrint(true);
  }, []);

  const handleShowPlayerModal = (record) => {
    const { id } = record;
    setIsOpenModal(true);
    dispatch(getWaveFileAction.request({ id, token }));
    setModalContent(<AudioPlayer audioRef={audioRef} />);
    setModalWidth(360);
    setCurrentRecord(record);
    setShowPrint(false);
  };

  const columns = [
    {
      title: t('records.expandable.mobileNumber'),
      key: 'telephoneNumber',
      dataIndex: 'telephoneNumber',
      ...FilterColumnSearch('telephoneNumber'),
      render: (text, record) => (
        <Button onClick={() => handleShowChartModal(record, typeChart)}>
          {text || '--'}
        </Button>
      ),
      responsive: ['md', 'lg'],
      width: 80,
      align: 'center',
    },
    {
      title: t('records.agency'),
      key: 'agencyName',
      dataIndex: 'agencyName',
      render: (text, record) => <span>{text || record.companyName}</span>,
      responsive: ['md', 'lg'],
      width: 80,
      filters: renderAgenciesFilter(),
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, sound) => handleAgenciesFilter(value, sound),
      sorter: (a, b) => (
        (a.agencyName || a.companyName).localeCompare(b.agencyName || b.companyName)
      ),
      align: 'center',
    },
    {
      title: t('records.town'),
      key: 'commune',
      dataIndex: 'commune',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
      width: 60,
      sorter: (a, b) => a.commune.localeCompare(b.commune),
      align: 'center',
    },
    {
      title: t('records.department'),
      key: 'department',
      dataIndex: 'department',
      align: 'center',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
      width: 30,
    },
    {
      title: t('records.date'),
      dataIndex: 'timeStamp',
      key: 'timeStamp',
      render: (timeStamp) => formatDate(timeStamp, t('format.fullDate'), initialLang),
      sorter: (a, b) => a.timeStamp - b.timeStamp,
      filterDropdown: renderFilterDropdown,
      onFilter: handleRangeDateFilter,
      width: 60,
      responsive: ['md', 'lg'],
      align: 'center',
    },
    {
      title: t('records.hour'),
      width: 60,
      dataIndex: 'timeStamp',
      key: 'timeStamp',
      render: (timestamp) => formatDate(timestamp, t('format.time'), initialLang),
      responsive: ['md', 'lg'],
      align: 'center',
    },
    {
      title: t('Audio'),
      width: 30,
      dataIndex: 'player',
      key: 'player',
      render: (_, record) => (
        <PlayCircleOutlined
          onClick={() => handleShowPlayerModal(record)}
        />
      ),
      responsive: ['md', 'lg'],
      align: 'center',
    },
    ...(role === 1 ? [{
      title: t('records.triplets'),
      width: 40,
      dataIndex: 'triplets',
      key: 'triplets',
      responsive: ['md', 'lg'],
      align: 'center',
    }] : []),
    {
      title: t('records.decision'),
      width: 60,
      dataIndex: 'decision',
      key: 'decision',
      filters: [
        {
          text: 'Pas de fuite',
          value: 0,
        },
        {
          text: 'Fuite',
          value: 1,
        },
        {
          text: 'Fuite suspectée',
          value: 10,
        },
      ],
      filterMultiple: true,
      onFilter: handleDecisionFilter,
      render: (text) => renderDecisionAlgo(text, t),
      responsive: ['md', 'lg'],
      align: 'center',
    },
    {
      title: t('records.qualification'),
      width: 80,
      dataIndex: 'qualification',
      key: 'qualification',
      responsive: ['md', 'lg'],
      align: 'center',
      render: (_, record) => {
        const { comment, qualification } = record;
        const qualificationValue = getQualificationValue(qualification);
        return (
          <>
            <span>{t(`records.${qualificationValue}`)}</span>
            { comment && comment.length > 0 && (
            <Popover className="ml-small" content={comment} trigger="hover">
              <MessageOutlined />
            </Popover>
            )}
          </>
        );
      },
    },
    {
      title: t('records.state'),
      width: 100,
      dataIndex: 'analysisTags',
      key: 'analysisTags',
      responsive: ['md', 'lg'],
      align: 'center',
      render: (tags, record) => {
        const defaultValue = tags ? tags[tags.length - 1].tag : 0;
        const tagColor = getTagColor(defaultValue);
        const tagText = getTagText(defaultValue, t);
        const hasTags = tags?.length > 0;
        const tagClassName = hasTags ? 'clickable' : '';

        if (editTagRowKey === record.key) {
          return (
            <>
              <Select
                className="record-tag-select"
                placeholder="changer état"
                defaultValue={defaultValue}
                onChange={(value) => handleTagChange(value, record.id)}
                options={tagsOptions}
              />
              <Button
                className="button-reset"
                icon={<CloseOutlined />}
                onClick={() => setEditTagRowKey(null)}
              />
            </>

          );
        }
        return (
          <>
            <Tag
              onClick={() => handleClickTag(tags, record)}
              className={tagClassName}
              color={tagColor}
              icon={getTagIcon(defaultValue)}
            >
              {tagText}
            </Tag>
            <Button
              className="button-reset"
              icon={<EditOutlined size={12} />}
              onClick={() => setEditTagRowKey(record.key)}
            />
          </>
        );
      },
    },
    {
      title: t('records.blockchain'),
      dataIndex: 'blockChainHash',
      key: 'blockChainHash',
      render: (text) => (
        <Popover content={blockChainPopover(text)} trigger="click">
          <Button icon={<SafetyCertificateOutlined />} />
        </Popover>
      ),
      responsive: ['md', 'lg'],
      width: 50,
      align: 'center',
    },
    {
      title: t('records.synthesis'),
      dataIndex: 'synthesis',
      key: 'synthesis',
      render: (_, record) => (
        <Button onClick={() => handleShowDrawer(record)}>
          {t('records.infos')}
        </Button>
      ),
      width: 60,
      responsive: ['md', 'lg'],
      align: 'center',
    },

  ];

  return (
    <TableTemplate
      className="records-list"
      columns={columns}
      data={tableData}
      typeList="fullHeight"
      pagination={{ pageSize: 20, showSizeChanger: false }}
      onChange={(_, filters) => {
        setDecisionFilterSelected(filters.decision || [0, 1, 10]);
        setFilterByAgencies(filters.agencyName);
      }}
    />
  );
};

RecordsList.propTypes = {
  typeChart: PropTypes.string.isRequired,
  setTimelineTags: PropTypes.func.isRequired,
  setIsOpenTimelineTagDrawer: PropTypes.func.isRequired,
  recordsList: PropTypes.arrayOf(PropTypes.shape({
    telephoneNumber: PropTypes.string.isRequired,
    androidId: PropTypes.string.isRequired,
    longitude: PropTypes.number.isRequired,
    latitude: PropTypes.number.isRequired,
    fileName: PropTypes.string.isRequired,
    agencyName: PropTypes.string.isRequired,
  })).isRequired,
  rangeDateFilterSelected: PropTypes.arrayOf(PropTypes.number).isRequired,
  setRangeDateFilterSelected: PropTypes.func.isRequired,
  setDecisionFilterSelected: PropTypes.func.isRequired,
  setCurrentRecord: PropTypes.func.isRequired,
  handleShowDrawer: PropTypes.func.isRequired,
  setIsOpenModal: PropTypes.func.isRequired,
  setModalContent: PropTypes.func.isRequired,
  setModalWidth: PropTypes.func.isRequired,
  currentRecord: PropTypes.shape({}).isRequired,
  setTypeChart: PropTypes.func.isRequired,
  setShowPrint: PropTypes.func.isRequired,
  audioRef: PropTypes.func.isRequired,
  setFilterByAgencies: PropTypes.func.isRequired,
};

export default RecordsList;
