import axiosInstance from './interceptor';

export const apiServ = process.env.REACT_APP_API_URL;
export const apiVersion = process.env.REACT_APP_API_VERSION;

export const constructApiUrl = (url) => `${apiServ}/${apiVersion}/${url}`;

const headers = (data) => {
  const { accessToken, refreshToken } = data;
  const header = !accessToken ? {
    headers: {
      Authorization: `Bearer ${data}`,
      'Content-Type': 'application/json',
    },
  }
    : {
      headers: {
        access_token: accessToken,
        refreshToken,
        'Content-Type': 'application/json',
      },
    };
  return header;
};

const get = async (apiUrl, token) => {
  const urlAPI = constructApiUrl(apiUrl);
  const response = await axiosInstance.get(urlAPI, headers(token));
  return response;
};

const create = async (url, data, origin) => {
  const urlAPI = constructApiUrl(url);
  const token = origin === 'canal' ? data[1] : data.token;
  const dataToSend = origin === 'canal' ? [data[0]] : data;

  const response = await axiosInstance.post(urlAPI, dataToSend, headers(token));
  return response.data ? response.data : response;
};

const update = async (url, data, origin) => {
  const { token } = data;
  const urlAPI = constructApiUrl(url);
  const dataToSend = origin === 'mobileStatus' ? data.status : data;
  const response = await axiosInstance.patch(urlAPI, dataToSend, headers(token));
  return response;
};

const auth = async (url, user) => {
  const urlAPI = constructApiUrl(url);
  const response = await axiosInstance.post(urlAPI, user);
  return response.headers;
};

export default {
  get,
  create,
  update,
  auth,
};
