import { Button, DatePicker, Space } from 'antd';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useState } from 'react';

const { RangePicker } = DatePicker;

const DateFilterDropdown = ({
  setSelectedKeys, selectedKeys, confirm, clearFilters,
}) => {
  const currentDateFromNow = dayjs();
  const maxIntervalFromCurentDate = currentDateFromNow.subtract(90, 'days');
  const selectedDates = selectedKeys[0];
  const firstDate = selectedDates ? selectedDates[0] : maxIntervalFromCurentDate;
  const endDate = selectedDates ? selectedDates[1] : currentDateFromNow;

  const [startDate, setStartDate] = useState(null);

  const handleApply = () => {
    confirm();
  };

  const handleReset = () => {
    setSelectedKeys([]);
    clearFilters();
    setStartDate(null);
  };

  const handlePickerChange = (dates) => {
    setSelectedKeys(dates ? [dates] : []);
  };

  const isBeforeTarget = ({ current, target }) => current.isBefore(target, 'day');
  const isAfterTarget = ({ current, target }) => current.isAfter(target, 'day');

  const isDateDisabled = (current) => {
    const isBeforeDefault = isBeforeTarget({ current, target: maxIntervalFromCurentDate });
    const isAfterDefault = isAfterTarget({ current, target: currentDateFromNow });
    if (!startDate) return false;

    if (current.isAfter(currentDateFromNow, 'day')) return true;

    if (startDate) {
      const minDate = startDate.subtract(90, 'days');
      const maxDate = currentDateFromNow || startDate.add(90, 'days');
      const isBefore = isBeforeTarget({ current, target: minDate });
      const isAfter = isAfterTarget({ current, target: maxDate });
      const res = isBefore || isAfter;
      return res;
    }

    return isBeforeDefault || isAfterDefault;
  };

  const handleChangeStartDate = (start) => {
    setStartDate(start);
  };

  return (
    <div style={{ padding: 8 }}>
      <Space direction="vertical">
        <RangePicker
          value={[firstDate, endDate]}
          onChange={handlePickerChange}
          format="DD-MM-YYYY"
          onCalendarChange={([start]) => start && handleChangeStartDate(start)}
          disabledDate={isDateDisabled}
        />
        <Space style={{ marginTop: 8 }}>
          <Button onClick={handleReset}>Réinitialiser</Button>
          <Button onClick={handleApply}>Valider</Button>
        </Space>
      </Space>
    </div>
  );
};

DateFilterDropdown.propTypes = {
  setSelectedKeys: PropTypes.func,
  selectedKeys: PropTypes.arrayOf(PropTypes.number),
  confirm: PropTypes.func,
  clearFilters: PropTypes.func,
};

DateFilterDropdown.defaultProps = {
  setSelectedKeys: () => {},
  confirm: () => {},
  clearFilters: () => {},
  selectedKeys: [1, 10, 0],
};

export default DateFilterDropdown;
