import {
  ANALYSIS, ANALYSISDETAILS, ANALYSISTAG, WAVEFILE,
} from '../actions/analysisAction';

const INITIAL_STATE = {
  analysis: null,
  message: null,
  loading: false,
  details: [],
  loadingDetails: false,
  loadingWaveFile: false,
  wavefile: '',
};

const analysisReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ANALYSIS.FETCH.REQUEST:
      return { ...state, loading: true };
    case ANALYSIS.FETCH.SUCCESS:
      return { ...state, analysis: payload, loading: false };
    case ANALYSIS.FETCH.ERROR:
      return { ...state, message: payload, loading: false };
    case ANALYSIS.FETCH.FAILURE:
      return { ...state, loading: false, message: payload };
    case ANALYSISTAG.POST.REQUEST:
      return { ...state, loading: true };
    case ANALYSISTAG.POST.SUCCESS:
      return { ...state, loading: false };
    case ANALYSISTAG.POST.FAILURE:
      return { ...state, loading: false };
    case ANALYSISDETAILS.FETCH.REQUEST:
      return { ...state, loadingDetails: true };
    case ANALYSISDETAILS.FETCH.SUCCESS:
      return { ...state, details: payload, loadingDetails: false };
    case ANALYSISDETAILS.FETCH.FAILURE:
      return { ...state, loadingDetails: false };
    case WAVEFILE.FETCH.REQUEST:
      return { ...state, loadingWaveFile: true };
    case WAVEFILE.FETCH.SUCCESS:
      return { ...state, wavefile: payload, loadingWaveFile: false };
    case WAVEFILE.FETCH.FAILURE:
      return { ...state, loadingWaveFile: false };
    default:
      return state;
  }
};

export default analysisReducer;
