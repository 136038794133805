import React, {
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import DashboardList from '../SharedStyle';
import TableTemplate from '../TableTemplate';
import { formatDate, getQualificationValue, renderDecisionAlgo } from '../../../utils/utils';
import { Context } from '../../../utils/context/Intlcontext';

const MobileDetailsList = ({
  currentMobile,
  setMapData,
  setCurrentHoverRowList,
  tableData,
  setTableData,
  currentRecord,
  rangeDateFilterSelected,
  decisionFilterSelected,
  origin,
  filterByAgencies,
}) => {
  const { analysis } = useSelector((state) => state.analysis);
  const { initialLang } = useContext(Context);
  const { t } = useTranslation();

  const processFilteredSounds = (filteredSounds) => {
    const data = [];
    const sortedSounds = filteredSounds.sort((a, b) => b.timeStamp - a.timeStamp);
    sortedSounds.forEach((sound) => {
      const formattedDate = formatDate(sound.timeStamp, t('format.dayTime'), initialLang);
      data.push({
        key: sound.blockChainHash,
        date: formattedDate,
        place: sound.commune,
        decision: sound.decision,
        qualification: sound.qualification,
        power: sound.leakPower,
        frequency: sound.leakFrequency,
        latitude: sound.latitude,
        longitude: sound.longitude,
        timestamp: sound.timeStamp,
        comment: sound.comment,
      });
    });
    setTableData(data);
    setMapData(data);
  };

  const renderDataByDecisionAndRangeDate = () => {
    if (analysis) {
      const startDate = rangeDateFilterSelected[0];
      const endDate = rangeDateFilterSelected[1];

      const startTimestamp = dayjs(startDate).valueOf();
      const endTimestamp = dayjs(endDate).valueOf();
      const filteredSounds = analysis.filter((record) => {
        const isWithinDateRange = (
          record.timeStamp >= startTimestamp && record.timeStamp <= endTimestamp
        );
        const matchesDecisionFilter = decisionFilterSelected.includes(record.decision);
        if (filterByAgencies && filterByAgencies.length > 0) {
          const matchAgenciesFilter = filterByAgencies.includes(record.agencyName);
          return isWithinDateRange && matchesDecisionFilter && matchAgenciesFilter;
        }
        return isWithinDateRange && matchesDecisionFilter;
      });
      processFilteredSounds(filteredSounds);
    }
  };

  const renderDataByPhoneNumber = () => {
    if (analysis && currentMobile) {
      const { telephoneNumber, selectedMonth } = currentMobile;
      const selectedMonthYearFormat = formatDate(selectedMonth, t('format.monthYear'));
      const filteredSounds = analysis.filter((record) => {
        const recordMonthYearFormat = formatDate(record.timeStamp, t('format.monthYear'));
        const mobileMatch = record.telephoneNumber === telephoneNumber;
        const timestampMatch = recordMonthYearFormat === selectedMonthYearFormat;
        return mobileMatch && timestampMatch;
      });
      processFilteredSounds(filteredSounds);
    }
  };

  useEffect(() => {
    if (origin === 'records') {
      renderDataByDecisionAndRangeDate();
    } else {
      renderDataByPhoneNumber();
    }
  }, [analysis, rangeDateFilterSelected, decisionFilterSelected, currentMobile, filterByAgencies]);

  const columns = [
    {
      title: t('dashboard.mobileDetails.day'),
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      responsive: ['md', 'lg'],
      width: 50,
    },
    {
      title: t('dashboard.mobileDetails.place'),
      dataIndex: 'place',
      key: 'place',
      align: 'center',
      responsive: ['md', 'lg'],
      width: 45,
    },
    {
      title: t('dashboard.mobileDetails.decision'),
      dataIndex: 'decision',
      key: 'decision',
      align: 'center',
      render: (_, sound) => {
        const { decision } = sound;
        return renderDecisionAlgo(decision, t);
      },
      responsive: ['md', 'lg'],
      width: 40,
    },
    {
      title: t('dashboard.mobileDetails.qualification'),
      dataIndex: 'qualification',
      key: 'qualification',
      align: 'center',
      render: (_, sound) => {
        const { comment, qualification } = sound;
        const qualificationValue = getQualificationValue(qualification);
        return (
          <>
            <span>{t(`records.${qualificationValue}`)}</span>
            { comment && comment.length > 0 && (
              <Popover className="ml-small" content={comment} trigger="hover">
                <MessageOutlined />
              </Popover>
            )}
          </>
        );
      },
      width: 55,
    },
    {
      title: t('dashboard.mobileDetails.power'),
      dataIndex: 'power',
      key: 'power',
      align: 'center',
      render: (_, sound) => Math.round(sound.power),
      responsive: ['md', 'lg'],
      width: 45,
    },
    {
      title: t('dashboard.mobileDetails.frequency'),
      dataIndex: 'frequency',
      key: 'frequency',
      align: 'center',
      responsive: ['md', 'lg'],
      width: 45,
    },
  ];

  const height = window.innerHeight - 110;

  return (
    <DashboardList padding="10px" width="46%" margin="20px" maxHeight={`${height}px`}>
      <TableTemplate
        typeList="fullHeight"
        origin="drawer"
        columns={columns}
        data={tableData}
        setCurrentHoverRowList={setCurrentHoverRowList}
        rowClassName={(record) => (origin === 'records' && record.key === currentRecord?.blockChainHash ? 'bg-white-light' : '')}
      />
    </DashboardList>
  );
};

MobileDetailsList.propTypes = {
  currentMobile: PropTypes.shape({
    telephoneNumber: PropTypes.string,
    selectedMonth: PropTypes.number,
  }),
  setMapData: PropTypes.func.isRequired,
  setCurrentHoverRowList: PropTypes.func.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setTableData: PropTypes.func.isRequired,
  currentRecord: PropTypes.shape({
    blockChainHash: PropTypes.string,
  }),
  origin: PropTypes.string,
  filterByAgencies: PropTypes.arrayOf(PropTypes.string),
  rangeDateFilterSelected: PropTypes.arrayOf(PropTypes.string),
  decisionFilterSelected: PropTypes.arrayOf(PropTypes.string),
};

MobileDetailsList.defaultProps = {
  currentMobile: {
    telephoneNumber: '',
    selectedMonth: '',
  },
  currentRecord: {
    blockChainHash: null,
  },
  origin: '',
  filterByAgencies: [],
  rangeDateFilterSelected: [],
  decisionFilterSelected: [],
};

export default MobileDetailsList;
